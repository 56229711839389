<template>
  <div class="app-container">
    <div class="top">
      <div class="top_min">
        <img
          src="https://image.xicaishe.com/1679038118681_xl86pD.jpg"
          alt=""
          class="animate__animated animate__rotateInUpLeft top_img"
        />
        <div class="top_left">
          <div class="top_text animate__animated animate__bounceInRight">
            简单高效的学习必备APP <br />让成功变得更加容易
          </div>
          <div class="top_text_min">
            <div class="top_er">
              <img src="@/assets/img/anzhuo-download.png" alt="" v-if="ischek == '2'" />
              <img src="@/assets/img/ios-download.png" alt="" v-else />
            </div>
            <div class="top_er2">
              <div
                class="btn"
                :id="ischek == '1' ? 'bg' : 'border'"
                @click="onTab(1)"
              >
                <img
                  src="https://image.xicaishe.com/1679039355443_KrYr33.jpg"
                  alt=""
                  v-if="ischek == '1'"
                />
                <img
                  src="https://image.xicaishe.com/1679039341747_3LL4eK.jpg"
                  alt=""
                  v-else
                />
                IOS下载
                <img
                  src="https://image.xicaishe.com/1679039365408_5yRmHF.jpg"
                  alt=""
                  v-show="ischek == '1'"
                />
              </div>
              <div
                class="btn"
                :id="ischek == '2' ? 'bg' : 'border'"
                @click="onTab(2)"
              >
                <img
                  src="https://image.xicaishe.com/1679039348557_EFcWPZ.jpg"
                  alt=""
                  v-if="ischek == '2'"
                />
                <img
                  src="https://image.xicaishe.com/1679039325452_y0ICbE.jpg"
                  alt=""
                  v-else
                />
                安卓下载
                <img
                  src="https://image.xicaishe.com/1679039365408_5yRmHF.jpg"
                  alt=""
                  v-show="ischek == '2'"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="qrcode-box">
      <p class="title animate__heartBeat textdong">推荐微信公众号</p>
      <ul class="qrcode-content clearfix">
        <li data-v-024d7c98="">
          <img src="@/assets/img/qrcode-ycxt_258.jpg" alt="小学" class="qrcode-img" />
          <div class="qrcode-title">易橙学堂</div>
          <div class="qrcode-desc">
            易橙学堂为学员提供丰富的课程内容和优质的教学服务，致力于帮助学员顺利通过军队文职考试，实现自我发展与成长。
          </div>
        </li>
        <li data-v-024d7c98="">
          <img src="@/assets/img/qrcode-ycsyb_258.jpg" alt="初中" class="qrcode-img" />
          <div class="qrcode-title">易橙事业编</div>
          <div class="qrcode-desc">
            易橙事业编是专注于事业编在线教育的平台，提供高质量的课程、考试资讯和备考指导，帮助广大考生顺利通过事业编考试。
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>
  
<script>
import Wow from "wow.js";
export default {
  name: "Download",
  data() {
    return {
      ischek: 1,
      imgurl: "https://image.xicaishe.com/1678952050220_RN8dKH.jpg",
    };
  },
  methods: {
    onTab(num) {
      this.ischek = num;
    },
  },
  mounted() {
    const wow = new Wow({
      // 对所有拥有该class的元素挂载动画
      boxClass: "textdong",
      // 需要挂载的动画元素
      animateClass: "animate__animated",
      // 偏移值（即偏移一定距离后再执行动画，推荐设置！）
      offset: 100,
      // 移动设备是否生效
      mobile: true,
      // 是否支持异步
      live: true,
    });
    wow.init();
  },
};
</script>

<style lang="scss" scoped>
#bg {
  background: linear-gradient(180deg, #fff9f5 0%, #ffebdf 100%);
  box-shadow: 0px 10px 10px 0px rgba(168, 44, 0, 0.2);
}
#border {
  border: 1px #fff solid;
  color: white;
  justify-content: space-around;
}
.top {
  width: 100%;
  height: 30vw;
  background-image: url("https://image.xicaishe.com/1679038044122_5557i7.jpg");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  overflow: hidden;
  min-height: 650px;
  .top_min {
    width: 60%;
    min-width: 1400px;
    height: 30vw;
    margin: 0 auto;
    min-height: 650px;
    position: relative;
    .top_img {
      width: 30vw;
      min-width: 560px;
      position: absolute;
      bottom: 0px;
    }
    .top_left {
      width: 600px;
      height: 650px;
      position: absolute;
      right: 180px;
      top: 140px;
      .top_text {
        font-size: 40px;
        color: white;
        font-weight: bold;
        line-height: 70px;
      }
      .top_text_min {
        display: flex;
        flex-flow: row wrap;
        .top_er {
          width: 40%;
          > img {
            width: 184px;
            margin: 20px;
            margin-top: 40px;
            margin-left: 0px;
          }
        }
        .top_er2 {
          width: 40%;
          .btn {
            width: 280px;
            height: 72px;
            border-radius: 54px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: 30px;
            color: #ff6200;
            line-height: 72px;
            padding-right: 40px;
            padding-left: 40px;
            font-weight: bold;
            margin-top: 40px;
            cursor: pointer;
            > img {
              width: 30px;
            }
          }
        }
      }
    }
  }
}
.qrcode-box {
  position: relative;
  margin: 0 auto;
  padding-top: 64px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding-bottom: 80px;
  > .title {
    position: relative;
    text-align: center;
    font-size: 36px;
    color: #000;
    font-size: 40px;
  }
  > .qrcode-content {
    position: relative;
    list-style: none;
    width: 1200px;
    height: 340px;
    margin: 50px auto 0;
    display: flex;
    justify-content: space-around;
  }
  > .qrcode-content li {
    width: 340px;
    height: 340px;
    float: left;
    text-align: center;
    margin: 0 48px;
  }
  > .qrcode-content li {
    .qrcode-img {
      width: 164px;
      height: 168px;
    }
    > .qrcode-title {
      font-size: 20px;
      line-height: 80px;
      color: #343434;
    }
    > .qrcode-desc {
      line-height: 30px;
      font-size: 14px;
      color: #545454;
    }
  }
}
</style>
  